import {ReactNode, createContext, useContext, useState} from 'react';

interface IMenuState {
  isActive: boolean;
}

interface IMenuContext extends IMenuState {
  open: () => void;
  close: () => void;
}

const MenuContext = createContext<IMenuContext | undefined>(undefined);

function MenuContextProvider({children}: {children: ReactNode}) {
  const [isActive, setIsActive] = useState<boolean>(false);

  const open = () => {
    setIsActive(true);
  };
  const close = () => {
    setIsActive(false);
  };

  return (
    <MenuContext.Provider value={{isActive, open, close}}>
      {children}
    </MenuContext.Provider>
  );
}

function useMenuContext() {
  const context = useContext(MenuContext);

  if (context === undefined) {
    throw Error('useMenuContext must be used within a MenuContext');
  }

  return context;
}

export {MenuContextProvider, useMenuContext};
